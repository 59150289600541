@import "../../base/styles/general/settings";
$mobileNewsLetter-background: $interactions;
$mobileNewsLetter-background-type2: $interactions;
$mobileNewsLetter-form-redesign-button-background: $headings;
$mobileNewsLetter-form-redesign-button-border-color: $headings;
$popularEvents-header-title-color: $headings;
$popularEvents-header-background-img: 'themes/football_vbr/img/section-header-pattern/section-header-pattern.png';

$homepage-gf-headline-color: $headings;

$topMatches-title-color: $interactions;
$topMatches-item-background-hover: $interactions;
$topMatches-item-details-color: $headings;
$topMatches-item-mobile-details-hover-background: $interactions;
$topMatches-item-details-price-discount-bg: $headings;
$topMatches-item-details-price-withoutDiscount-color: $white;
$topMatches-item-details-price-withDiscount-color: $white;
$topMatches-item-details-price-withDiscount-color-2: $headings !default;
$topMatches-item-details-price-withoutDiscount-color-2: $headings !default;
$topOffers-item-hover-details-background: $interactions;
$topOffers-item-details-background: rgba($headings, 0.88);

$arrangementOfTheWeek-title-color: $headings;
$arrangementOfTheWeek-i-color: $headings;
$arrangementOfTheWeek-date-background: $headings;
$arrangementOfTheWeekin-Price-background: $headings;
$arrangementOfTheWeek-price-text-color: $interactions;
$arrangementOfTheWeek-bookBtn-background: $interactions;
$arrangementOfTheWeek-bookBtn-hover-background: $headings;

$paymentInstallmentPromotion-background: $headings;
$paymentInstallmentPromotion-highLighted-color: $interactions;
$paymentInstallmentPromotion-links-hover-color: $interactions-hover-color;

@import "../../football/styles/homepage";

BODY .homepage .container {
	&.popular_events .row {
		.header {
			background-image: url('#{$default_dir}#{$popularEvents-header-background-img}');
		}

		.clubsContainer .club {
			img, .details {
				border-radius: 5px;
			}

			.details {
				.booking {
					background-color: $interactions;

					a.button {
						color: $white;
						background-color: transparent;
						border: 2px solid $white;
						border-radius: 5px;
					}
				}
			}

			&:hover {
				.details:before {
					display: none;
				}
			}
		}
	}

	&.description {
		margin: 20px auto 0 auto;

		h2 {
			color: $headings;
			text-align: center;
		}

		a {
			color: $interactions;
		}
	}

	&.arrangement_of_the_week .row .container {
		&-1, &-2 .images {
			img {
				border-radius: 5px;
			}
		}
	}
}